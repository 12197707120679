<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :quickFilterFieldsNumber="3"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showEditButton
    @edit="(id) => onEdit('TelemarketingTaskForCurrentUserEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'TelemarketingTasksForCurrentUser',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'TelemarketingTasksForCurrentUser',
      tableCaption: 'Задания по телемаркетингу для текущего пользователя',
      breadcrumbs: [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Задания по телемаркетингу для текущего пользователя',
        },
      ],
    };
  },

  computed: {
    hidePersonalData() {
      const hidePersonalData = localStorage.getItem('hidePersonalData');
      return hidePersonalData === 'true' ? true : false;
    },

    tableHeaders() {
      const headers = [
        { text: '№ задания', alias: 'id', order: 'id' },
        // { text: 'Исполнитель', alias: 'fio', order: 'fio' },
        { text: 'Продукт телемаркетинга', alias: 'product', order: 'product' },
        { text: 'Скрипт телемаркетинга', alias: 'script', order: 'script' },
        { text: 'Теги', alias: 'tags', order: 'tags' },
        { text: 'Комментарий', alias: 'comment', order: 'comment' },
        { text: 'Результат обзвона', alias: 'callResult', order: 'callResult' },
        {
          text: 'Название организации',
          alias: 'organizationName',
          order: 'organizationName',
        },
        {
          text: 'Адрес организации',
          alias: 'organizationAddress',
          order: 'organizationAddress',
        },
        // {
        //   text: 'Телефон организации',
        //   alias: 'organizationPhone',
        //   order: 'organizationPhone',
        // },
        {
          text: 'Email организации',
          alias: 'organizationMail',
          order: 'organizationMail',
        },
        {
          text: 'Веб-сайт организации',
          alias: 'organizationUrl',
          order: 'organizationUrl',
        },
        {
          text: 'ИНН организации',
          alias: 'organizationInn',
          order: 'organizationInn',
        },
        {
          text: 'ФИО контактного лица',
          alias: 'contactFio',
          order: 'contactFio',
        },
        {
          text: 'ФИО директора',
          alias: 'chief',
          order: 'chief',
        },
        {
          text: 'Мобильный телефон контактного лица',
          alias: 'contactPhoneMobile',
          order: 'contactPhoneMobile',
        },
        {
          text: 'Рабочий телефон контактного лица',
          alias: 'contactPhoneWork',
          order: 'contactPhoneWork',
        },
        {
          text: 'Email контактного лица',
          alias: 'contactMail',
          order: 'contactMail',
        },
        {
          text: 'Должность контактного лица',
          alias: 'contactPosition',
          order: 'contactPosition',
        },
        {
          text: 'Дата создания',
          alias: 'created',
          order: 'created',
        },
        {
          text: 'Дата выполнения задания',
          alias: 'completionDate',
          order: 'completionDate',
        },
        { alias: 'actions' },
      ];
      const additionalheder = {
        text: 'Телефон организации',
        alias: 'organizationPhone',
        order: 'organizationPhone',
      };

      if (!this.hidePersonalData) {
        headers.splice(6, 0, additionalheder);
      }
      return headers;
    },
  },
};
</script>
